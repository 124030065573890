import React, { useEffect, useState } from "react";
import { Modal, Card, Container, Col, Row, Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { netaxeptPaymentMethodsFin, netaxeptPaymentMethodsFinForTink } from "../../paymentProviders";
import { paymentService } from "../../../../services/payment";
import { userGetCountry, userRequestHeaders, userGetLocale } from "../../../../services/user";
import { mapLocaleToHeaders } from "../../../../services/helpers";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

const messages = defineMessages({
    choosePaymentMethod: {
        id: "Valitse maksutapa",
        defaultMessage: "Valitse maksutapa"
    },
    actionClose: {
        id: "Sulje",
        defaultMessage: "Sulje"
    },
    actionCancel: {
        id: "Peruuta",
        defaultMessage: "Peruuta"
    },
    errorOccurred: {
        id: "Tapahtui virhe",
        defaultMessage: "Tapahtui virhe",
    },
    somethingWentWrongTryAgain: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
    },
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin",
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    }
});
export { messages };


const BankSelectionModal = (props) => {
    // translations
    const lng = props.intl.formatMessage;
    const [banks, setBanks] = useState([]);
    const [tinkBanks, setTinkBanks] = useState([]);
    const [error, setError] = useState(false);

    // get Tink payment methods when initialized
    useEffect(() => {
        getTinkPaymentMethods();
    }, []);


    useEffect(() => {
        if (props.show) {
            if (error && props.serviceProvider === paymentService.PAYMENTMETHODS.TINK) {
                // in error cases close modal, but if other service providers are available in the environment, allow paying with them
                props.handleBankSelectionModal();
                // show error message to user
                props.handleAlertDialog(lng(messages.somethingWentWrongTryAgain), 'danger');
            } else if (userGetCountry() === 'fi' && props.serviceProvider === paymentService.PAYMENTMETHODS.NETAXEPT) {
                // map Netaxept payment methods
                setBanks(netaxeptPaymentMethodsFin.providers);
            } else if (userGetCountry() === 'fi' && props.serviceProvider === paymentService.PAYMENTMETHODS.TINK) {
                // map Tink payment methods
                setBanks(tinkBanks);
            } else {
                // bank selection not supported, skip in Ropo Online and redirect straight to payment provider to choose the bank
                props.handleChoosePaymentMethod();
            }
        }

    }, [props.show]);


    /**
     * Get payment methods from Tink.
     */
    function getTinkPaymentMethods() {
        const headers = userRequestHeaders("application/pdf");
        // Add language
        headers['Accept-Language'] = mapLocaleToHeaders(userGetLocale());

        // HTTP Request to get available payment methods from Tink
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/paymentproviders',
            headers: headers
        }).then(function(res) {

            if (userGetCountry() === 'fi') {
                let tinkModalBanks = [...res.data.providers];

                // add different styling for logos from CDN
                tinkModalBanks.map((bank) => {
                    bank.style = 'tinkstyle';
                    return bank;
                });

                // add some banks from Netaxept to Tink bank selection modal and sort alphabetically
                for (let i = 0; netaxeptPaymentMethodsFinForTink.providers.length > i; i++) {
                    tinkModalBanks.push(netaxeptPaymentMethodsFinForTink.providers[i]);
                }
                tinkModalBanks.sort(function(a, b) { return a.name.localeCompare(b.name)});

                // set combination of providers from response and hardcoded options
                setTinkBanks(tinkModalBanks);
            } else {
                // set providers without mutations (no need to add banks from other service providers)
                setTinkBanks(res.data.providers);
            }
        }).catch(function(error) {
            if (error.response?.status === 401) {
                const alert = {message: lng(messages.authError), type: 'danger'};
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else {
                // set error
                setError(true);
            }
        });
    }

    return (
        <Modal show={props.show} onHide={props.handleBankSelectionModal} size="lg">
            <Modal.Header className="white-header mb-0 pb-0" closeButton={true} closeLabel={props['aria-label'] || lng(messages.actionClose)}>
                <Button className="modal-back" onClick={() => props.handleBankSelectionModal()}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> { lng(messages.backToInvoice) }
                </Button>
                <h4 className="modal-title">{lng(messages.choosePaymentMethod)}</h4>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {/* list payment methods */}
                    <Row>
                        {banks.length > 0 ?
                            (banks.map((row) => {
                                    return (
                                        <Col md={{ span: 3, order: row.rank }} sm={{ span: 4, order: row.rank }} xs={{ span: 6, order: row.rank }} key={row.bank_id} className="mb-4">
                                            <Card onClick={() => props.handleChoosePaymentMethod(row.bank_id)} className="payment-method h-100" id={row.name}>
                                                <Card.Img alt={row.name} id={row.name} variant="top" src={row.logo} className={row.style} />
                                                <Card.Header className="align-items-stretch h-100 p-0">
                                                    <Card.Text aria-label={row.name} className="text-center payment-methods">
                                                        {row.name}
                                                    </Card.Text>
                                                </Card.Header>
                                            </Card>
                                        </Col>
                                    )
                                })
                            ) : ('')
                        }
                    </Row>
                    {/* Cancel button mobile only  */}
                    <div className="d-block d-md-none d-grid pt-2 pb-2">
                        <Button size="lg" variant="secondary" onClick={() => props.handleBankSelectionModal()}>{lng(messages.actionCancel)}</Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

BankSelectionModal.propTypes = {
    'aria-label': PropTypes.string,
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'serviceProvider': PropTypes.string,
    'handleBankSelectionModal': PropTypes.func,
    'handleChoosePaymentMethod': PropTypes.func,
    'handleAlertDialog': PropTypes.func,
    'history': PropTypes.object
};

export default BankSelectionModal;